<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div v-if="educationInfo.plantName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-label-text title="사업장" :value="educationInfo.plantName"></c-label-text>
        </div>
        <!-- 교육과정 -->
        <div v-if="educationInfo.educationCourseName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-label-text title="교육과정" :value="educationInfo.educationCourseName"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 교육기간 -->
          <c-datepicker
            :range="true"
            name="educationPeriod"
            defaultStart="-6M"
            defaultEnd="0M"
            label="교육기간"
            v-model="educationPeriod"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <!-- 교육과정별 교육계획 및 결과 -->
      <c-table
        ref="table"
        title="교육과정별 교육계획 및 결과"
        :columns="grid.columns"
        :data="grid.data"
        :editable="editable"
        :merge="grid.merge"
        rowKey="eduEducationId"
        checkDisableColumn="disable"
        :checkClickFlag="false"
        :isFullScreen="false"
        :filtering="false"
        :isExcelDown="false"
        :columnSetting="false"
        @linkClick="linkClick"
      >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!-- <q-btn
              icon="help"
              color="deep-purple-6"
              text-color="white"
              class="custom-btn"
              align="center"
              round>
              <q-tooltip anchor="bottom left" self="top left">
                <div class="tooltipCustomTop"> 대상자 업로드 안내</div>
                <div class="tooltipCustom">
                  <div class="q-pl-xs" style="text-align: left">
                    1. 교육과정별 교육계획을 선택합니다.<br/>
                    2. [교육계획별 대상자 템플릿 다운로드] 버튼을 클릭합니다. <br/>
                    3. [교육대상자 업로드] 버튼을 클릭합니다.<br/>
                    4. 다운된 파일로 엑셀 업로드를 하여 적용을 시킵니다. 
                  </div>
                </div>
              </q-tooltip>
            </q-btn>
            <c-btn v-if="editable" label="템플릿 다운로드" icon="print" @btnClicked="print" />
            <c-btn v-if="editable" label="교육대상자 업로드" :editable="editable" icon="assignment" @btnClicked="openExcelUploader"/>
            <c-btn v-if="editable && grid.data.length > 0" label="선택" icon="check" @btnClicked="select" />
            <c-btn v-if="editable" label="교육계획 추가" icon="add" @btnClicked="linkClick" /> -->
            <c-btn label="검색"  icon="search" @btnClicked="getHistoryList" />
          </q-btn-group>
        </div>
      </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>
<script>

import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
import XLSX from 'xlsx'
export default {
  name: 'education-course-history',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
    educationInfo: {
      type: Object,
      default: () => ({
        plantName: '',
        educationCourseName: '',
        educationKindCdLargeName: '',
        educationKindCdSmallName: '',
        educationTypeName: '',
        legalEducationFlagName: '',
        educationPurpose: '',
        educationTimeName: '',
        relatedLawsName: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: "educationName",
            field: "educationName",
            // 교육명
            label: "교육명",
            style: 'width:250px',
            align: "left",
            sortable: true,
            type: "link",
          },
          // {
          //   name: "documentStatusName",
          //   field: "documentStatusName",
          //   label: "진행상태",
          //   style: 'width:90px',
          //   align: "center",
          //   sortable: true,
          // },
          {
            name: "educationDate",
            field: "educationDate",
            // 교육기간
            label: "교육기간",
            style: 'width:140px',
            align: "center",
            sortable: true,
          },
          {
            name: 'educationLocation',
            field: 'educationLocation',
            // 교육장소
            label: '교육장소',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: "educationMethodName",
            field: "educationMethodName",
            style: 'width:90px',
            // 교육방법
            label: "교육방법",
            align: "center",
            sortable: true,
          },
          {
            name: "educationPurpose",
            field: "educationPurpose",
            style: 'width:300px',
            // 학습목적
            label: "학습목적",
            align: "left",
            sortable: true,
          },
        ],
        height: '500px',
        data: [],
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      educationPeriod: [],
      excelUrl: '',
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.excelUrl = transactionConfig.sop.edu.plan.insert.exceltargetUsers.url
      this.listUrl = selectConfig.sop.edu.result.list.url;
      this.getHistoryList();
    },
    getHistoryList() {
      if (this.popupParam.eduCourseId) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.educationInfo.plantCd,
          eduCourseId: this.popupParam.eduCourseId,
          educationPeriod: this.educationPeriod,
          exceptDocumentStatusCd: 'ESC000001'
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data
        },);
      }
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row) {
      this.popupOptions.title = "교육계획 및 결과 상세"; // 교육계획 및 결과 상세
      this.popupOptions.param = {
        eduEducationId: row ? row.eduEducationId : '',
        eduCourseId: this.educationInfo.eduCourseId,
        stepCd: !row || row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getHistoryList();
    },
    select() {
      this.popupOptions.title = "교육과정별 대상자 연명부"; // 교육과정별 대상자 연명부
      this.popupOptions.param = {
        plantCd: this.educationInfo.plantCd,
        eduCourseId: this.educationInfo.eduCourseId,
        educationPeriod: this.educationPeriod,
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/educationTargetUsersPop.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '95%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    print() {
      let selectData = this.$refs['table'].selected;
      if (selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '교육계획을 선택하세요.', // 교육계획을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        let printData = [];
        this.$_.forEach(selectData, _item => {
          printData.push({
            '교육계획일련번호': _item.eduEducationId,
            '교육명': _item.educationName,
            '교육기간': _item.educationDate,
            '사번': '',
            '이름': '',
          })
        })
        const worksheet = XLSX.utils.json_to_sheet(printData);
        worksheet["!cols"] = [
          { wpx: 100 },
          { wpx: 400 },
          { wpx: 200 },
          { wpx: 100 },
          { wpx: 100 },
        ]
        const workBook = {
          SheetNames: ['대상자'], // sheet name
          Sheets: {
            '대상자': worksheet,
          }
        };
        XLSX.writeFile(workBook, '업로드용(' + this.educationInfo.educationCourseName + ').xlsx');
      }
    },
    openExcelUploader() {
      this.popupOptions.title = '교육계획별 대상자 업로드'; // 교육계획별 대상자 업로드
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'./educationCourseExcelUpload.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelPopup;
    },
    closeExcelPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        let postData = [];
        this.$_.forEach(s_data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            empNo: _item.empNo,
            eduEducationId: _item.eduEducationId
          });
          if (index === -1) {
            postData.splice(0, 0, {
              eduEducationId: _item.eduEducationId,
              empNo: _item.empNo,
              attendeesTypeCd: 'EATC00003',
              attendeesName: _item.userName,
              editFlag: 'C'
            })
          }
            // 직책 EATC00001
        })
        /* eslint-disable no-unused-vars */ 
        this.$http.url = this.excelUrl;
        this.$http.type = 'POST';
        this.$http.param = postData;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        },);
      }
    },
  }
};
</script>